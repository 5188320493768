<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-4">
    <button
      v-if="can('destroy', 'donations') && !isDeleted"
      class="btn btn-danger ml-auto"
      type="button"
      title="Delete Donation"
      @click="$emit('deleteDonation')"
    >
      <font-awesome-icon icon="trash" /> Delete
    </button>
    <button
      v-if="can('update', 'donations') && isDeleted"
      class="btn btn-success ml-auto"
      type="button"
      title="Restore Donation"
      @click="$emit('restoreDonation')"
    >
      <font-awesome-icon icon="trash-restore" /> Restore
    </button>
  </nav>
</template>

<script>
export default {
  name: 'DonationNavigation',
  props: {
    donation: {
      type: Object,
      required: true,
    },
    isDeleted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
